var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "m-0 base-dropdown__item d-flex align-items-center justify-content-between w-100",
    class: {
      'base-dropdown__item--logout': ['Log Out', 'Delete Comment'].includes(_vm.text)
    },
    style: {
      fontWeight: _vm.fontWeight
    }
  }, [_c('span', {
    staticClass: "d-flex align-items-center base-dropdown__item--span pointer-none"
  }, [_vm.icon ? _c('span', {
    staticClass: "base-dropdown__icon d-flex justify-content-center align-items-center"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": _vm.icon,
      "height": "auto",
      "width": "auto"
    }
  })], 1) : _vm._e(), _vm._v(" " + _vm._s(_vm.text) + " ")]), _vm._t("icon")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }