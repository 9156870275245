var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_vm.companyLogoId ? _c('b-img-lazy', {
    staticClass: "profile-image",
    attrs: {
      "alt": "company logo",
      "src": _vm.companyLogoUrl,
      "height": "30px",
      "width": "30px"
    }
  }) : _c('ba-base-icon', {
    attrs: {
      "name": "ba-sunbeam"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }