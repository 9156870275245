
import Vue from "vue";

export default Vue.extend({
    props: {
        label: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        classText: {
            type: String,
            required: false,
            default: "transparent",
        }
    },
    data() {
        return {
            value: "",
        };
    },
    methods: {
        initiateValue(value: any) {
            const v = value;
            if (v) {
                this.value = v;
            }
        },
    },
    mounted() {
        this.initiateValue(this.$attrs.value);
    },
    watch: {
        // Everytime the value changes it emits it
        "value": function (v) {
            this.$emit("input", v);
        },
        "$attrs.value": function (v) {
            this.initiateValue(v);
        },
    },
});
