
import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
    inheritAttrs: true,
    props: {
        label: {
            type: String,
            default: "",
        },
        noLabel: {
            type: Boolean,
            default: false,
        },
        noDivider: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
        },
        fileName: {
            type: String,
        },
        fileId: {
            type: String,
        },
        size: {
            type: String,
        },
    },
    methods: {
        ...mapActions({
            fetchFile: "file/fetchFile",
        }),
        async viewFile() {
            try {
                const { presignedUrl } = await this.fetchFile(this.fileId);
                window.open(presignedUrl, "_blank");
            } catch (e) {
                this.$bvToast.toast("", {
                    title: "Unable to fetch file!",
                    variant: "danger",
                    solid: true,
                });
            }
        },
    },
});
