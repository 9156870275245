var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-inline-flex"
  }, [_c('input', {
    ref: "file-upload-input",
    staticClass: "d-none",
    attrs: {
      "id": "file",
      "accept": "image/*",
      "name": "image",
      "type": "file"
    },
    on: {
      "change": function ($event) {
        return _vm.processFileUpload($event.target.files[0]);
      }
    }
  }), _c('ba-button', {
    class: `${_vm.classText} text-decoration-none border-0 font-weight-bold`,
    attrs: {
      "disabled": _vm.disabled,
      "icon": _vm.buttonIcon,
      "text": _vm.buttonText,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.triggerUpload
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }