var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-avatar', {
    staticClass: "pointer relative",
    attrs: {
      "size": "160",
      "badge-offset": "-0.2em",
      "badge-variant": ""
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.openImagePicker.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "badge",
      fn: function () {
        return [_c('b-avatar', {
          attrs: {
            "variant": "blue-100"
          }
        }, [_c('ba-base-icon', {
          attrs: {
            "name": "ba-upload",
            "height": "20px",
            "width": "20px"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [!_vm.image ? _c('ba-base-icon', {
    attrs: {
      "name": "ba-blank-profile"
    }
  }) : _c('b-img', {
    attrs: {
      "center": "",
      "fluid": "",
      "src": _vm.preview
    }
  })], 1), _c('input', {
    ref: "imagePicker",
    staticClass: "form-control-file d-none",
    attrs: {
      "type": "file",
      "accept": "image/*",
      "id": "file__avatar"
    },
    on: {
      "change": _vm.previewImage
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }