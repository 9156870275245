var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 h-100 d-flex flex-column justify-content-center bg__wrapper"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": _vm.icon
    }
  }), _vm.isArrowIcon ? _c('ba-base-icon', {
    staticClass: "arrow-icon",
    attrs: {
      "name": "ba-arrow-icon"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "empty--text mt-4",
    domProps: {
      "innerHTML": _vm._s(_vm.emptyText)
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }