var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-inline-block"
  }, [_c('div', {
    staticClass: "relative d-flex justify-content-center align-items-center"
  }, [_c('b-spinner', {
    staticStyle: {
      "width": "5rem",
      "height": "5rem",
      "position": "absolute",
      "left": "auto",
      "right": "auto",
      "animation-duration": "550ms"
    },
    attrs: {
      "variant": "blue-100",
      "label": "Text Centered"
    }
  }), _c('b-spinner', {
    staticStyle: {
      "width": "5rem",
      "height": "5rem"
    },
    attrs: {
      "variant": "primary",
      "label": "Text Centered"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }