
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { HOME, SETTINGS, AUTH, HELP } from "@/app/routes/endpoints";
import ChangePasswordModal from "@/app/components/modals/ChangePasswordModal.vue";

export default Vue.extend({
    components: {
        ChangePasswordModal,
    },
    data() {
        return {
            role: "Administrator",
            HOME,
            SETTINGS,
            AUTH,
            HELP,
            showMyRoles: false,
            showAvatarOptions: false,
        };
    },
    async mounted() {
        if (this.profileImageId) {
            await this.getFileUrl(this.profileImageId);
        }
    },
    computed: {
        ...mapGetters({
            getUserData: "auth/getUserData",
            files: "file/getFiles",
            companyData: "company/getProfile",
        }),
        ...mapGetters({ profileData: "auth/getUserData" }),
        profileImageId(): string {
            const { profileImgId } = this.getUserData;
            return profileImgId;
        },
        profileImageUrl(): string {
            const imageObj = this.files[this.profileImageId];
            return (imageObj && imageObj.presignedUrl) || null;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        imageOrAvatar(): any {
            return this.profileImageUrl ? { src: this.profileImageUrl } : { icon: "people-fill" };
        },
        isMenuAccessible(): boolean {
            return this.profileData?.roles?.includes("Admin") || this.profileData?.roles?.includes("ReportCreator");
        },
    },
    methods: {
        ...mapActions({
            fetchFile: "file/fetchFile",
        }),
        goTo({ name }: { name: string }) {
            const routeData = this.$router.resolve({ name });
            window.open(routeData.href, "_blank");
        },
        async getFileUrl(filename: string): Promise<void> {
            try {
                await this.fetchFile(filename);
            } catch (e) {
                this.$bvToast.toast("", { title: "Unable to fetch file!", variant: "danger", solid: true });
            }
        },
        goHome() {
            this.$router.push({ name: HOME.INDEX.NAME });
        },
        logout() {
            localStorage.clear();
            this.$router.push({ name: AUTH.LOGIN.NAME });
        },
        openMyRoles() {
            if (this.showAvatarOptions) this.showAvatarOptions = false;
            this.showMyRoles = !this.showMyRoles;
        },
        openAvatarOptions() {
            if (this.showMyRoles) this.showMyRoles = false;
            this.showAvatarOptions = !this.showAvatarOptions;
        },
    },
});
