
import Vue from "vue";

export default Vue.extend({
    inheritAttrs: true,
    props: {
        label: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
        },
        hint: {
            type: String,
        },
    },
    data() {
        return {
            checkboxValue: false,
        };
    },
    computed: {
        value(): string {
            return this.$attrs.value;
        },
    },
    watch: {
        value(v) {
            this.checkboxValue = v;
        },
        checkboxValue(curr, prev) {
            if (curr !== prev) {
                this.$emit("input", curr);
            }
        },
    },
});
