var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ba-radio"
  }, [_c('input', {
    attrs: {
      "id": _vm.id,
      "type": "radio",
      "name": _vm.name,
      "disabled": _vm.disabled
    },
    domProps: {
      "value": _vm.optionValue,
      "checked": _vm.shouldBeSelected
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('input', $event.target.value);
      }
    }
  }), _c('label', {
    class: {
      disabled: _vm.disabled,
      'has-label': !!_vm.label
    },
    attrs: {
      "for": _vm.id
    }
  }, [!!_vm.label ? _c('span', [_vm._v(_vm._s(_vm.label))]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }