var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-button', _vm._g(_vm._b({
    class: `d-flex align-items-center button__class button-padding--${_vm.padding} ${_vm.textClass}`,
    attrs: {
      "disabled": _vm.loading,
      "type": _vm.type
    }
  }, 'b-button', _vm.$attrs, false), _vm.$listeners), [_vm.loading ? _c('b-spinner', {
    staticClass: "mr-2",
    attrs: {
      "label": "Loading...",
      "small": ""
    }
  }) : _vm._e(), (_vm.image || _vm.icon) && !_vm.loading ? _vm._t("image", function () {
    return [_vm.image ? _c('b-img-lazy', {
      attrs: {
        "alt": `${_vm.name}`,
        "src": _vm.image
      }
    }) : _vm._e(), _vm.icon ? _c('ba-base-icon', {
      class: {
        'mr-2': !!_vm.text
      },
      attrs: {
        "name": _vm.icon,
        "width": "auto",
        "height": "auto"
      }
    }) : _vm._e()];
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.text) + " "), _vm._t("right")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }