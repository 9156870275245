var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    class: {
      'mx-0': true,
      'py-5 rounded px-2 flagged': _vm.isFlagged
    },
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "text-black h5"
  }, [_vm._t("title")], 2), _c('div', {
    staticClass: "text-gray-800 text-sm-3"
  }, [_vm._t("description")], 2)]), _c('b-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('div', {
    staticClass: "text-sm-3"
  }, [_vm._t("body")], 2)]), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }