var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('div', {
    staticClass: "label--text text-gray-900 py-2"
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.required ? _c('span', {
    staticClass: "text-danger pl-1 h6 pt-1"
  }, [_vm._v("*")]) : _vm._e()]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-dropdown', {
    staticClass: "ba-dropdown__items dropdown-field",
    attrs: {
      "no-caret": "",
      "variant": "transparent"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "h-100"
        }, [_c('div', {
          staticClass: "d-flex justify-content-center"
        }, [_c('b-img', {
          attrs: {
            "src": _vm.selected.flag,
            "blank-color": "black",
            "height": "15px",
            "width": "25px",
            "onerror": "this.style.display='none'"
          }
        })], 1), _c('div', {
          staticClass: "d-flex justify-content-between align-items-center mt-1"
        }, [_c('div', {
          staticClass: "text-capitalize mr-2 text-sm text-black"
        }, [_c('div', [_vm._v(_vm._s(_vm.selected.callingCode))])]), _c('b-icon', {
          attrs: {
            "icon": "chevron-down",
            "font-scale": "0.8"
          }
        })], 1)])];
      },
      proxy: true
    }])
  }, _vm._l(_vm.countryCodes, function (countryCode, ix) {
    return _c('b-dropdown-item', {
      key: ix,
      on: {
        "click": function ($event) {
          _vm.selected = countryCode;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-img-lazy', {
      attrs: {
        "src": countryCode.flag,
        "blank-color": "black",
        "blank": true,
        "height": "15px",
        "width": "25px",
        "alt": `${countryCode.countryCode} country flag`
      }
    }), _c('div', {
      staticClass: "ml-2"
    }, [_c('div', {
      staticClass: "text-sm"
    }, [_vm._v(" " + _vm._s(countryCode.countryCode) + " ")]), _c('div', {
      staticClass: "text-sm text-black"
    }, [_vm._v(" " + _vm._s(countryCode.callingCode) + " ")])])], 1)]);
  }), 1), _c('b-input', _vm._g(_vm._b({
    class: [`bg-${_vm.classText}`, _vm.isCountryRequired ? 'code-border' : 'input__field'],
    attrs: {
      "placeholder": _vm.placeholder,
      "maxLength": "11"
    },
    on: {
      "keypress": function ($event) {
        return _vm.isNumber($event);
      }
    }
  }, 'b-input', _vm.$attrs, false), _vm.$listeners))], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }