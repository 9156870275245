var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "ba-dropdown ba-dropdown__container"
  }, [_c('b-col', {
    attrs: {
      "cols": _vm.dCols
    }
  }, [_c('div', {}, [_c('div', {
    staticClass: "label--text text-gray-900 py-2"
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.required ? _c('span', {
    staticClass: "text-danger h6"
  }, [_vm._v("*")]) : _vm._e()]), _c('b-dropdown', _vm._g(_vm._b({
    staticClass: "ba-dropdown__menu input__field",
    class: `${_vm.formClass}`,
    attrs: {
      "no-caret": "",
      "menu-class": "w-100 my-0 py-0",
      "variant": _vm.variant,
      "boundary": "viewport"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-row', {
          attrs: {
            "align-h": "between",
            "align-v": "center"
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "10"
          }
        }, [_c('div', {
          staticClass: "text-capitalize text-left text-truncate w-100"
        }, [_vm._v(_vm._s(_vm.formText))])]), _c('b-col', {
          attrs: {
            "cols": "2"
          }
        }, [_c('div', {
          staticClass: "d-flex justify-content-end text-right"
        }, [_c('b-icon', {
          attrs: {
            "icon": "chevron-down",
            "font-scale": "0.8"
          }
        })], 1)])], 1)];
      },
      proxy: true
    }])
  }, 'b-dropdown', _vm.$attrs, false), _vm.$listeners), _vm._l(_vm.options, function (option, ix) {
    return _c('b-dropdown-item', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      key: ix,
      class: {
        'bg-gray-500': _vm.showSelection && _vm.selection.includes(option)
      },
      attrs: {
        "title": _vm.showTooltip ? option.tooltip : ''
      },
      on: {
        "click": function ($event) {
          return _vm.handleSelected(option);
        }
      }
    }, [_c('div', {
      staticClass: "py-3 option--text"
    }, [_vm._v(_vm._s(_vm.itemText ? option[_vm.itemText] : option))])]);
  }), 1)], 1)]), _vm.showSelection && this.selection.length > 0 ? _c('b-col', {
    attrs: {
      "cols": _vm.sCols
    }
  }, [_c('div', {
    staticClass: "ba-dropdown__selection d-flex flex-column rounded border border-gray-500 mb-4 px-2 py-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "text-sm text-uppercase"
  }, [_vm._v(_vm._s(_vm.selectionLabel))])]), _c('div', {
    staticClass: "d-flex mt-2 flex-wrap"
  }, _vm._l(_vm.selection, function (item, ix) {
    return _c('b-badge', {
      key: ix,
      staticClass: "pl-3 rounded py-2 mb-3 mr-3 bg-gray-20 text-black-70 d-flex align-items-center",
      attrs: {
        "pill": ""
      }
    }, [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(_vm.itemText ? item[_vm.itemText] : item))]), _c('ba-base-icon', {
      staticClass: "ml-2 pointer",
      staticStyle: {
        "min-height": "12px"
      },
      attrs: {
        "name": "ba-subtract-icon"
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.removeItem(ix);
        }
      }
    })], 1);
  }), 1), _c('section', {
    staticClass: "d-flex justify-content-between mt-5"
  }, [_c('b-dropdown', _vm._g(_vm._b({
    staticClass: "ba-dropdown__menu input__field set--width",
    class: `${_vm.formClass}`,
    attrs: {
      "no-caret": "",
      "menu-class": "w-100 my-0 py-0",
      "variant": _vm.variant,
      "boundary": "viewport"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-row', {
          attrs: {
            "align-h": "start",
            "align-v": "center"
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "11"
          }
        }, [_c('div', {
          staticClass: "text-capitalize text-left text-truncate w-100"
        }, [_vm._v(_vm._s(_vm.formText))])]), _c('b-col', {
          attrs: {
            "cols": "1"
          }
        }, [_c('div', {
          staticClass: "d-flex justify-content-end text-right"
        }, [_c('b-icon', {
          attrs: {
            "icon": "chevron-down",
            "font-scale": "0.8"
          }
        })], 1)])], 1)];
      },
      proxy: true
    }], null, false, 2053257897)
  }, 'b-dropdown', _vm.$attrs, false), _vm.$listeners), _vm._l(_vm.options, function (option, ix) {
    return _c('b-dropdown-item', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      key: ix,
      class: {
        'bg-gray-500': _vm.showSelection && _vm.selection.includes(option)
      },
      attrs: {
        "title": _vm.showTooltip ? option.tooltip : ''
      },
      on: {
        "click": function ($event) {
          return _vm.handleSelected(option);
        }
      }
    }, [_c('div', {
      staticClass: "py-3 option--text"
    }, [_vm._v(_vm._s(_vm.itemText ? option[_vm.itemText] : option))])]);
  }), 1), _c('div', {
    staticClass: "text-gray-dark border border-gray-30 px-4 py-2 rounded-8 d-flex pointer",
    on: {
      "click": _vm.clearSelection
    }
  }, [_vm._v("Clear All")])], 1)])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }