var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ba-breadcrumb d-flex align-items-center flex-wrap"
  }, [_c('button', {
    staticClass: "ba-breadcrumb__button border-0 bg-transparent m-0 p-0 d-flex align-items-center",
    on: {
      "click": _vm.goBack
    }
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-arrow-left"
    }
  }), _c('span', {
    staticClass: "back-text"
  }, [_vm._v(_vm._s(_vm.backText))])], 1), !!_vm.textOne || !!_vm.textTwo ? _c('p', {
    staticClass: "ba-breadcrumb__text d-flex align-items-center flex-wrap mb-0"
  }, [!!_vm.textOne ? _c('span', {
    staticClass: "text-black-50"
  }, [_vm._v(_vm._s(_vm.textOne))]) : _vm._e(), !!_vm.textTwo ? _c('span', {
    staticClass: "text-yellow-150"
  }, [_vm._v("/ " + _vm._s(_vm.textTwo))]) : _vm._e()]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }