var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ba-bodadmin-logo"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "text-sm mr-2 font-weight-bold",
    class: {
      'text-black': _vm.dark,
      'text-white': !_vm.dark
    }
  }, [_vm._v("POWERED BY")]), _c('ba-base-icon', {
    attrs: {
      "name": _vm.dark ? 'bodadmin-logo-dark' : 'bodadmin-logo-light',
      "height": "15px",
      "width": "80px"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }