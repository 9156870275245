var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column border border-gray-500 mb-4 rounded px-2 w-100"
  }, [_c('div', {
    staticClass: "text-sm pt-2 pb-2 text-uppercase"
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.required ? _c('span', {
    staticClass: "text-danger pl-1 h6 pt-1"
  }, [_vm._v("*")]) : _vm._e()]), _c('div', {
    staticClass: "py-2 d-flex border border-left-0 border-right-0 align-items-center"
  }, [_c('div', [_c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "transparent"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "text-sm mr-1"
        }, [_vm._v("Text Style")]), _c('b-icon', {
          attrs: {
            "icon": "chevron-down",
            "font-scale": "0.75"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, _vm._l(_vm.textStyles, function (textStyle, ix) {
    return _c('b-dropdown-item', {
      key: ix
    }, [_vm._v(_vm._s(textStyle))]);
  }), 1)], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "pointer",
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleBold().run();
      }
    }
  }, [_c('ba-base-icon', {
    class: {
      'text-blue-800': _vm.editor.isActive('bold')
    },
    attrs: {
      "name": "ba-editor-bold",
      "height": "16px"
    }
  })], 1), _c('div', {
    staticClass: "pointer",
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleItalic().run();
      }
    }
  }, [_c('ba-base-icon', {
    class: {
      'text-blue-800': _vm.editor.isActive('italic')
    },
    attrs: {
      "name": "ba-editor-italic",
      "height": "16px"
    }
  })], 1), _c('div', {
    staticClass: "pointer"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-editor-link",
      "height": "16px"
    }
  })], 1), _c('div', {
    staticClass: "pointer"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-editor-uo-list",
      "height": "16px"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex mx-2"
  }, [_c('div', {
    staticClass: "pointer"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-editor-text-color",
      "height": "16px"
    }
  })], 1), _c('div', {
    staticClass: "pointer"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-editor-text-size",
      "height": "16px"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex mx-2"
  }, [_c('div', {
    staticClass: "pointer",
    class: {
      'text-blue-800': _vm.editor.isActive({
        textAlign: 'left'
      })
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().setTextAlign('left').run();
      }
    }
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-editor-align-left",
      "height": "16px"
    }
  })], 1), _c('div', {
    staticClass: "pointer",
    class: {
      'text-blue-800': _vm.editor.isActive({
        textAlign: 'center'
      })
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().setTextAlign('center').run();
      }
    }
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-editor-align-center",
      "height": "16px"
    }
  })], 1), _c('div', {
    staticClass: "pointer",
    class: {
      'text-blue-800': _vm.editor.isActive({
        textAlign: 'right'
      })
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().setTextAlign('right').run();
      }
    }
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-editor-align-right",
      "height": "16px"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex mx-2"
  }, [_c('div', {
    staticClass: "pointer",
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().insertTable({
          rows: 3,
          cols: 3,
          withHeaderRow: false
        }).run();
      }
    }
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-editor-table",
      "height": "16px"
    }
  })], 1), _c('div', {
    staticClass: "pointer"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-editor-image",
      "height": "16px"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "d-flex pointer"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-editor-attachment",
      "height": "16px"
    }
  }), _c('div', {
    staticClass: "text-sm text-black"
  }, [_vm._v("Attach file")])], 1)])]), _c('div', {
    staticClass: "pt-2"
  }, [_c('editor-content', {
    staticClass: "editor",
    attrs: {
      "editor": _vm.editor
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }