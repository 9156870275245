var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [!_vm.noLabel ? _c('div', {
    staticClass: "pt-2 text-black"
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), !_vm.noLabel ? _c('div', {
    staticClass: "text-sm-2 pt-2 mb-3"
  }, [_vm._v(_vm._s(_vm.description))]) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center file-wrapper"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('svg', {
    attrs: {
      "width": "17",
      "height": "16",
      "viewBox": "0 0 17 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M13.7396 3.44302C12.2441 1.94738 9.81045 1.94738 8.31462 3.44302L3.66443 8.09318C2.59617 9.16156 2.59624 10.8999 3.66457 11.9682C4.19878 12.5024 4.90027 12.7695 5.60203 12.7694C6.30361 12.7694 7.00547 12.5023 7.53959 11.9682L11.8021 7.70555C12.1127 7.39505 12.2837 6.9822 12.2838 6.54308C12.2838 6.10393 12.1128 5.6911 11.8022 5.3805C11.1611 4.73951 10.1181 4.73954 9.47714 5.38062L6.6896 8.16809C6.47559 8.38208 6.47559 8.72907 6.68956 8.9431C6.90354 9.15715 7.25053 9.15708 7.46456 8.9431L10.2521 6.15561C10.4658 5.94193 10.8134 5.94188 11.0271 6.15556C11.1306 6.25906 11.1877 6.39668 11.1877 6.54305C11.1877 6.68942 11.1307 6.827 11.0271 6.93054L6.76458 11.1932C6.12354 11.8341 5.08057 11.8342 4.43958 11.1932C3.79859 10.5522 3.79854 9.50916 4.43946 8.86812L9.08963 4.21803C10.158 3.14965 11.8964 3.14965 12.9646 4.21803C13.4822 4.73553 13.7673 5.42363 13.7673 6.15551C13.7673 6.8874 13.4822 7.5755 12.9646 8.09304L8.31455 12.7433C8.10056 12.9573 8.10056 13.3043 8.31459 13.5183C8.42161 13.6253 8.56186 13.6788 8.70209 13.6788C8.84234 13.6788 8.98259 13.6253 9.0896 13.5183L13.7396 8.86808C14.4642 8.14355 14.8632 7.18022 14.8633 6.15554C14.8633 5.13087 14.4642 4.16754 13.7396 3.44302Z",
      "fill": "#919090"
    }
  })]), _c('p', {
    staticClass: "mb-0 colun ml-1 mr-2"
  }, [_vm._v(":")])]), _c('div', {
    staticClass: "d-flex file-pdf__wrapper mr-4"
  }, [_c('ba-base-icon', {
    staticClass: "mr-3",
    attrs: {
      "name": "ba-file-pdf-new"
    }
  }), _c('p', {
    staticClass: "mb-0 file-pdf__title"
  }, [_vm._v(_vm._s(_vm.fileName))])], 1), _c('p', {
    staticClass: "mb-0 file-pdf__action pointer",
    on: {
      "click": _vm.viewFile
    }
  }, [_vm._v("Open")])]), _c('ba-base-icon', {
    attrs: {
      "name": "ba-menu-dots-new"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }