var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-modal-container relative"
  }, [_c('b-modal', _vm._b({
    ref: `modal-${_vm.id}`,
    attrs: {
      "id": `modal-${_vm.id}`,
      "size": _vm.size,
      "hide-footer": "",
      "centered": "",
      "hide-header": "",
      "content-class": "border-0"
    }
  }, 'b-modal', _vm.$attrs, false), [_vm._t("close-content", function () {
    return [_c('div', {
      staticClass: "d-flex align-items-center justify-content-end pr-4 mt-4"
    }, [_c('div', {
      staticClass: "text-right h2 pointer",
      on: {
        "click": function ($event) {
          _vm.$bvModal.hide(`modal-${_vm.id}`);
          _vm.$emit('hide');
        }
      }
    }, [_vm._v(" × ")])])];
  }), _vm.title || _vm.description ? [_c('div', {
    staticClass: "dialog__wrapper"
  }, [_c('h5', {
    staticClass: "dialog__header dialog__title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _vm.isIconRequired ? _c('ba-base-icon', {
    staticClass: "mt-1",
    attrs: {
      "name": _vm.icon
    }
  }) : _vm._e()], 1), _c('div', {
    class: _vm.isPaymentModal ? 'dialog__description--danger' : 'dialog__description',
    domProps: {
      "innerHTML": _vm._s(_vm.description)
    }
  })])] : _vm._e(), _c('div', {
    class: [_vm.customContentClass, {
      'pb-4 pt-2 px-4': _vm.applyBodyClasses
    }]
  }, [_vm._t("default")], 2), _vm.hasFooter ? _c('div', {
    staticClass: "dialog__footer"
  }, [_vm._t("footer")], 2) : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }