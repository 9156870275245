var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column border border-gray-500 mb-4 w-100",
    class: {
      rounded: !_vm.tile
    }
  }, [_c('div', {
    staticClass: "px-2 text-sm pt-2 text-uppercase mb-auto"
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.required ? _c('span', {
    staticClass: "text-danger pl-1 h6 pt-1"
  }, [_vm._v("*")]) : _vm._e()]), _c('div', {
    staticClass: "d-flex px-2 align-items-center"
  }, [_c('ba-base-icon', {
    staticClass: "pointer",
    attrs: {
      "name": "ba-reduce"
    },
    nativeOn: {
      "click": function ($event) {
        _vm.formValue--;
      }
    }
  }), _c('b-input', _vm._g({
    staticClass: "bg-transparent border-0 text-center",
    attrs: {
      "type": "number",
      "placeholder": _vm.placeholder
    },
    model: {
      value: _vm.formValue,
      callback: function ($$v) {
        _vm.formValue = $$v;
      },
      expression: "formValue"
    }
  }, _vm.$listeners)), _c('ba-base-icon', {
    staticClass: "pointer",
    attrs: {
      "name": "ba-increase"
    },
    nativeOn: {
      "click": function ($event) {
        _vm.formValue++;
      }
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }