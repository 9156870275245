
import Vue from "vue";

export default Vue.extend({
    props: {
        variant: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
    },
});
