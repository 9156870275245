
import Vue from "vue";

export default Vue.extend({
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
    },
});
