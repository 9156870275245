var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show ? _c('ul', {
    staticClass: "base-dropdown bg-white position-absolute list-unstyled mb-0",
    style: {
      top: _vm.top,
      left: _vm.left,
      width: _vm.width,
      right: _vm.right
    }
  }, [_vm._t("default")], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }