var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "modal-icon d-flex align-items-center justify-content-center mx-auto rounded-circle",
    class: `modal-icon--${_vm.variant}`
  }, [_vm.icon === 'info' ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "d": "M20.0005 35.5147C28.5693 35.5147 35.5157 28.5683 35.5157 19.9995C35.5157 11.4307 28.5693 4.48438 20.0005 4.48438C11.4317 4.48438 4.48535 11.4307 4.48535 19.9995C4.48535 28.5683 11.4317 35.5147 20.0005 35.5147Z",
      "fill": "#646364"
    }
  }), _c('path', {
    attrs: {
      "d": "M20.8891 18.3828H19.1113C18.9328 18.3828 18.7881 18.5275 18.7881 18.706V27.7565C18.7881 27.9351 18.9328 28.0798 19.1113 28.0798H20.8891C21.0676 28.0798 21.2123 27.9351 21.2123 27.7565V18.706C21.2123 18.5275 21.0676 18.3828 20.8891 18.3828Z",
      "fill": "#E9E9E9"
    }
  }), _c('path', {
    attrs: {
      "d": "M20.0004 15.1503C20.893 15.1503 21.6166 14.4267 21.6166 13.5341C21.6166 12.6415 20.893 11.918 20.0004 11.918C19.1079 11.918 18.3843 12.6415 18.3843 13.5341C18.3843 14.4267 19.1079 15.1503 20.0004 15.1503Z",
      "fill": "#E9E9E9"
    }
  })]) : _vm.icon === 'success' ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M34.1869 10.7843C33.7094 10.3103 32.9336 10.3103 32.456 10.7843L15.1372 27.9879L7.52268 20.4813C7.04511 20.0073 6.26935 20.0073 5.79178 20.4813C5.3142 20.9552 5.3142 21.7225 5.79178 22.1952L14.2754 30.5576C14.7481 31.0255 15.5348 31.0255 16.0075 30.5576L34.1869 12.4982C34.6657 12.0255 34.6657 11.257 34.1869 10.7843C34.6657 11.257 33.7094 10.3103 34.1869 10.7843Z",
      "fill": "#006C48"
    }
  })]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }