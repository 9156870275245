var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column pl-4 flex-lg-row align-items-center w-100 pt-4 border-0",
    class: {
      'bg-black': _vm.dark,
      'bg-white': !_vm.dark
    }
  }, [_c('div', {
    staticClass: "d-flex z-5 w-100"
  }, [_vm._t("prepend-title"), _c('div', {
    staticClass: "d-flex flex-column w-100"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "mr-3 w-auto"
  }, [_vm._t("title"), _vm._t("description")], 2), _c('div', {
    staticClass: "ml-0 ml-lg-auto z-5 w-auto"
  }, [_vm._t("actions")], 2)]), _vm._t("default")], 2)], 2), _c('div', {
    staticClass: "polka-dots"
  }, _vm._l(6, function (i) {
    return _c('div', {
      key: i,
      class: `ellipse-${i} ${_vm.dark ? 'bg-gray-100 opacity-1' : 'bg-gray-300'}`
    });
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }