
import Vue from "vue";

export default Vue.extend({
    inheritAttrs: true,
    props: {
        "text": { type: String, required: false, default: "" },
        "text-class": { type: String, required: false },
        "loading": { type: Boolean, default: false },
        "image": {
            type: String,
            required: false,
            default: "",
        },
        "icon": {
            type: String,
            required: false,
            default: "",
        },
        "type": {
            type: String,
            required: false,
            default: "button",
        },
        "padding": {
            type: String,
            required: false,
            default: "normal",
        },
    },
});
