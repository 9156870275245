
import Vue from "vue";

export default Vue.extend({
    props: {
        stroke: String,
    },
    computed: {
        boxStyle(): string {
            return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='${this.stroke}' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='36' stroke-linecap='round'/%3e%3c/svg%3e")`;
        },
    },
});
