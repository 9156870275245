var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-dropdown', {
    attrs: {
      "variant": "transparent",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "ba-notification pointer"
        }, [_c('ba-base-icon', {
          attrs: {
            "name": "ba-bell-plus"
          }
        }), _c('span', {
          staticClass: "ba-notification__badge translate-middle bg-danger rounded-circle"
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('div', {
    staticClass: "text-black"
  }, [_vm._v("Notifications")])]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    staticClass: "border-bottom"
  }, [_c('div', {
    staticClass: "d-flex align-items-start mr-5"
  }, [_c('div', {
    staticClass: "ba-notification__dot bg-blue-800 mr-2 mt-1"
  }), _c('div', [_c('div', {
    staticClass: "ba-notification__title text-black"
  }, [_vm._v(" Your profile has been updated successfully ")]), _c('div', {
    staticClass: "text-sm-3"
  }, [_vm._v("Now")])])])]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_c('div', {
    staticClass: "text-black"
  }, [_vm._v("Mark all as read")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }