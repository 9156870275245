
import Vue from "vue";

export default Vue.extend({
    inheritAttrs: true,
    props: {
        "label": {
            type: String,
            required: true,
        },
        "placeholder": {
            type: String,
        },
        "shouldShow": {
            type: Boolean,
            default: true,
        },
        "form-class": {
            type: String,
        },
        "options": {
            type: Array,
        },
        // column size for dropdown box
        "d-cols": {
            type: [String, Number],
            default: "12",
        },
        // column size for selection box area
        "s-cols": {
            type: [String, Number],
            default: 12,
        },
        "showSelection": {
            type: Boolean,
            default: false,
        },
        "selection-label": {
            type: String,
            default: "SELECTED",
        },
        "multiple": {
            type: Boolean,
            default: false,
        },
        "required": {
            type: Boolean,
            default: false,
        },
        "show-tooltip": {
            type: Boolean,
            default: false,
        },
        "returnObject": {
            type: Boolean,
            default: false,
        },
        "value": {
            type: [String, Object, Array, Number],
            default: "",
        },
        "itemText": {
            type: String,
            required: false,
            default: "",
        },
        "itemValue": {
            type: String,
            required: false,
            default: "",
        },
        "variant": {
            type: String,
            required: false,
            default: "transparent",
        },
    },
    data(): {
        formValue: any;
        formText: string;
        isFocused: boolean;
        selected: any;
        selection: any[];
    } {
        return {
            formValue: "",
            formText: "",
            isFocused: false,
            selected: "",
            selection: [],
        };
    },
    methods: {
        handleSelected(value: any) {
            this.setValue(value);

            this.emitValues();
        },
        emitValues() {
            if (!this.multiple) {
                if (this.itemValue) {
                    if (this.returnObject) {
                        this.$emit("input", this.selected);
                    } else {
                        this.$emit("input", this.selected[this.itemValue as any]);
                    }
                } else {
                    this.$emit("input", this.selected);
                }
            } else {
                console.log('Is multiple', this.selection, this.itemValue)
                // eslint-disable-next-line no-lonely-if
                if (this.itemValue) {
                    this.$emit("input", [...this.selection.map((selection) => selection[this.itemValue])]);
                } else {
                    this.$emit("input", [...this.selection]);
                }
            }
        },
        setValue(value: any) {
            if (!this.multiple) {
                this.formValue = value;

                this.selected = value;

                this.formText = this.itemText ? this.selected[this.itemText as any] : this.selected;
            } else {
                // eslint-disable-next-line no-lonely-if
                if (this.itemValue) {
                    if (this.selection.includes(value[this.itemValue])) {
                        const index = this.selection.indexOf(value[this.itemValue]);

                        this.selection.splice(index, 1);
                    } else {
                        this.selection = [...this.selection, value[this.itemValue]];
                    }
                } else {
                    // eslint-disable-next-line no-lonely-if
                    if (this.selection.includes(value)) {
                        const index = this.selection.indexOf(value);

                        this.selection.splice(index, 1);
                    } else {
                        this.selection = [...this.selection, value];
                    }
                }

                this.formValue = [...this.selection];
                // eslint-disable-next-line no-nested-ternary
                this.formText = this.label === "Other Regulator" ? "Select regulator" : this.label === "Roles" ? "Select roles" : "See Selection Below";
            }
        },
        clearSelection() {
            this.selection = [];
            this.emitValues();
        },
        removeItem(index: number) {
            // console.log(index, this.selection);
            this.selection.splice(index, 1);
            // console.log(index, this.selection);
            this.emitValues();
        },
        initiateValues(value: any) {
            const v = value;
            if (v) {
                if (this.multiple) {
                    (v as any[]).forEach((val) => {
                        this.setValue(val);
                    });
                } else {
                    const selected = this.itemValue ? this.options.find((option: any) => option[this.itemValue] === v) : v;

                    this.setValue(selected);
                }
            }
        },
    },
    mounted() {
        this.initiateValues(this.value);
    },
});
