<template>
    <div class="base-modal-container relative">
        <b-modal :id="`modal-${id}`" :ref="`modal-${id}`" :size="size" hide-footer centered v-bind="$attrs" hide-header content-class="border-0">
            <slot name="close-content">
                <div class="d-flex align-items-center justify-content-end pr-4 mt-4">
                    <div
                        class="text-right h2 pointer"
                        @click="
                            $bvModal.hide(`modal-${id}`);
                            $emit('hide');
                        ">
                        &times;
                    </div>
                </div>
            </slot>
            <template v-if="title || description">
                <div class="dialog__wrapper">
                    <h5 class="dialog__header dialog__title">
                        {{ title }}
                        <ba-base-icon v-if="isIconRequired" :name="icon" class="mt-1" />
                    </h5>
                    <div :class="isPaymentModal ? 'dialog__description--danger' : 'dialog__description'" v-html="description"></div>
                </div>
            </template>
            <div :class="[customContentClass, { 'pb-4 pt-2 px-4': applyBodyClasses }]">
                <slot />
            </div>
            <div v-if="hasFooter" class="dialog__footer">
                <slot name="footer" />
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    inheritAttrs: true,
    props: {
        "id": { type: [Number, String], required: true },
        "size": { type: String, default: "lg" },
        "custom-content-class": String,
        "title": String,
        "description": String,
        "closeBtn": Boolean,
        "isIconRequired": { type: Boolean, required: false, default: false },
        "icon": String,
        "hasFooter": { type: Boolean, required: false, default: false },
        "applyBodyClasses": { type: Boolean, required: false, default: true },
        "isPaymentModal": { type: Boolean, required: false, default: false },
    },
    methods: {},
};
</script>

<style lang="scss">
.modal {
    z-index: 10000000000000001 !important;
}
.modal-content {
    border-radius: 8px !important;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    overflow: hidden;

    .title {
        margin-top: -30px;
    }
}
.dialog {
    &__wrapper {
        margin-top: -40px;
        padding: 0 32px 12px;
        border-bottom: 1px solid #ededed;
    }
    &__title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #383637;
    }
    &__description {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #7a797a;
    }
    &__description--danger {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: red;
    }

    &__footer {
        position: relative;
        padding: 16px 32px;

        &::after {
            position: absolute;
            content: "";
            height: 1px;
            width: 1000px;
            background: $gray-40;
            top: -0px;
            left: -30px;
        }
    }
}
.modal-body {
    padding: 0 !important;
}
</style>
