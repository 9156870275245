var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column border border-gray-500 mb-4 rounded ba-checkbox"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between my-auto"
  }, [_vm._t("label", function () {
    return [_c('div', {
      staticClass: "px-2 text-sm py-2 text-uppercase"
    }, [_vm._v(_vm._s(_vm.label))])];
  }), _c('div', {
    staticClass: "d-flex flex-column align-items-end"
  }, [_c('b-form-checkbox', {
    staticClass: "mt-2 text-success",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.checkboxValue,
      callback: function ($$v) {
        _vm.checkboxValue = $$v;
      },
      expression: "checkboxValue"
    }
  }), _c('div', {
    staticClass: "px-2 pt-2"
  }, [_vm._v(_vm._s(_vm.hint))])], 1)], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }