var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "base-dropdown__toggler border-0 text-black-80 bg-transparent m-0 p-0"
  }, [_vm.direction === 'vertical' ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none"
    }
  }, [_c('g', {
    attrs: {
      "opacity": "0.8"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5.33317 8.0013C5.33317 8.35492 5.19269 8.69406 4.94265 8.94411C4.6926 9.19416 4.35346 9.33463 3.99984 9.33463C3.64622 9.33463 3.30708 9.19416 3.05703 8.94411C2.80698 8.69406 2.6665 8.35492 2.6665 8.0013C2.6665 7.64768 2.80698 7.30854 3.05703 7.05849C3.30708 6.80844 3.64622 6.66797 3.99984 6.66797C4.35346 6.66797 4.6926 6.80844 4.94265 7.05849C5.19269 7.30854 5.33317 7.64768 5.33317 8.0013ZM9.33317 8.0013C9.33317 8.35492 9.19269 8.69406 8.94265 8.94411C8.6926 9.19416 8.35346 9.33463 7.99984 9.33463C7.64621 9.33463 7.30708 9.19416 7.05703 8.94411C6.80698 8.69406 6.6665 8.35492 6.6665 8.0013C6.6665 7.64768 6.80698 7.30854 7.05703 7.05849C7.30708 6.80844 7.64621 6.66797 7.99984 6.66797C8.35346 6.66797 8.6926 6.80844 8.94265 7.05849C9.19269 7.30854 9.33317 7.64768 9.33317 8.0013ZM13.3332 8.0013C13.3332 8.35492 13.1927 8.69406 12.9426 8.94411C12.6926 9.19416 12.3535 9.33463 11.9998 9.33463C11.6462 9.33463 11.3071 9.19416 11.057 8.94411C10.807 8.69406 10.6665 8.35492 10.6665 8.0013C10.6665 7.64768 10.807 7.30854 11.057 7.05849C11.3071 6.80844 11.6462 6.66797 11.9998 6.66797C12.3535 6.66797 12.6926 6.80844 12.9426 7.05849C13.1927 7.30854 13.3332 7.64768 13.3332 8.0013Z",
      "fill": "#646364"
    }
  })])]) : _c('b-icon', {
    attrs: {
      "font-size": "20px",
      "icon": "three-dots-vertical"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }