var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "ba-navbar ba-navbar__container",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "d-flex align-items-center bg-black-100 h-100 navbar__wrapper"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('ba-base-icon', {
    staticClass: "mr-4 pointer",
    attrs: {
      "name": "ba-home-icon"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.goHome.apply(null, arguments);
      }
    }
  }), _c('div', [_c('ba-base-icon', {
    staticClass: "mr-2",
    attrs: {
      "name": "nga-logo"
    }
  })], 1), _c('div', {
    staticClass: "logo-text"
  }, [_vm._v("FINANCIAL REPORTING COUNCIL OF NIGERIA")])], 1), _c('div', {
    staticClass: "ml-auto d-flex align-items-center navbar__wrapper--right"
  }, [false ? _c('ba-notification-icon') : _vm._e(), _c('p', {
    staticClass: "mb-0 company-text"
  }, [_vm._v(_vm._s(_vm.companyData.companyProfile.name) + " –– RC (" + _vm._s(_vm.companyData.companyProfile.rcNumber) + ")")]), _c('ba-base-icon', {
    attrs: {
      "name": "h-line",
      "height": "auto",
      "width": "auto"
    }
  }), _c('div', {
    staticClass: "d-flex user-roles align-items-center position-relative"
  }, [_c('div', {
    staticClass: "base-dropdown__toggler user-roles__toggler text-center d-flex align-items-center",
    on: {
      "click": _vm.openMyRoles
    }
  }, [_vm._v(" My Roles "), _c('ba-base-icon', {
    staticClass: "pointer-none",
    attrs: {
      "name": "ba-new-arrow",
      "height": "auto",
      "width": "auto"
    }
  })], 1), _c('BaBaseDropdown', {
    attrs: {
      "show": _vm.showMyRoles,
      "width": "258px",
      "top": "50px",
      "right": "0px"
    },
    on: {
      "outside-clicked": function ($event) {
        _vm.showMyRoles = false;
      }
    }
  }, [_c('div', {
    staticClass: "user-roles__header d-flex justify-content-between"
  }, [_c('h6', {
    staticClass: "mb-0 text-black-90"
  }, [_vm._v("My roles")]), _c('BaBadge', {
    attrs: {
      "text": String(_vm.getUserData.roles.length),
      "variant": "dark"
    }
  })], 1), _c('div', {
    staticClass: "user-roles__list d-flex flex-wrap"
  }, _vm._l(_vm.getUserData.roles, function (role) {
    return _c('BaBadge', {
      key: role,
      attrs: {
        "text": role
      }
    });
  }), 1)])], 1), _c('div', {
    staticClass: "position-relative"
  }, [_c('div', {
    staticClass: "base-dropdown__toggler pointer",
    on: {
      "click": _vm.openAvatarOptions
    }
  }, [_c('BaUserCard')], 1), _c('BaBaseDropdown', {
    attrs: {
      "show": _vm.showAvatarOptions,
      "width": "258px",
      "top": "50px",
      "right": "0px"
    },
    on: {
      "outside-clicked": function ($event) {
        _vm.showAvatarOptions = false;
      }
    }
  }, [_c('BaUserCard', {
    attrs: {
      "show-text": true
    }
  }), _c('BaBaseDropdownItem', {
    attrs: {
      "text": "Personal Settings",
      "icon": "personal-settings"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$router.push({
          name: _vm.SETTINGS.PROFILE.NAME
        });
      }
    }
  }), _vm.isMenuAccessible ? _c('BaBaseDropdownItem', {
    attrs: {
      "text": "Company profile",
      "icon": "company-profile"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$router.push({
          name: _vm.SETTINGS.BUSINESS.NAME
        });
      }
    }
  }) : _vm._e(), _vm.isMenuAccessible ? _c('BaBaseDropdownItem', {
    attrs: {
      "text": "User, roles and profiles",
      "icon": "users"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$router.push({
          name: _vm.SETTINGS.USER_ACCESS.NAME
        });
      }
    }
  }) : _vm._e(), _c('BaBaseDropdownItem', {
    attrs: {
      "text": "Change Password",
      "icon": "change-password"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$bvModal.show('modal-change-password');
      }
    }
  }), _c('BaBaseDropdownItem', {
    attrs: {
      "text": "Help Centre",
      "icon": "ba-help-center"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.goTo({
          name: _vm.HELP.INDEX.NAME
        });
      }
    }
  }), _c('BaBaseDropdownItem', {
    attrs: {
      "text": "Log Out",
      "icon": "log-out"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.logout.apply(null, arguments);
      }
    }
  })], 1)], 1)], 1)])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ChangePasswordModal')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }