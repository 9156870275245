var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._b({
    staticClass: "bg-white pt-2 loader-wrapper posotion-relative",
    staticStyle: {
      "border-radius": "8px"
    }
  }, 'div', _vm.$attrs, false), [_vm.isLoading ? _c('div', {
    staticClass: "d-flex justify-content-center min-vh-50 align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3"
  }, [_c('ba-base-icon', {
    staticClass: "mr-2",
    attrs: {
      "name": 'ba-logo-standalone',
      "height": "50px",
      "width": "100px"
    }
  }), _c('ba-base-icon', {
    staticClass: "mr-2",
    attrs: {
      "name": "ba-frc-logo",
      "width": "61",
      "height": "61"
    }
  })], 1), _c('ba-loader'), _c('div', {
    staticClass: "text-black mt-4"
  }, [_vm._v(_vm._s(_vm.loadingText))])], 1)]) : _c('div', [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }