var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-5 justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "mb-3 pointer",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('ba-base-icon', {
    staticClass: "mr-1",
    attrs: {
      "name": "ba-left-blue"
    }
  }), _c('span', {
    staticClass: "back-text"
  }, [_vm._v("Back home")])], 1), _c('div', {
    staticClass: "company__wrapper"
  }, [_c('h3', {
    staticClass: "comapny__text--header"
  }, [_vm._v(_vm._s(_vm.title))]), _c('p', {
    staticClass: "comapny__text--description"
  }, [_vm._v(_vm._s(_vm.description))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }