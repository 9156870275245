var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "py-2 label--text"
  }, [_vm._v(" " + _vm._s(_vm.label)), _vm.required ? _c('span', {
    staticClass: "text-danger pl-1 h6 pt-1"
  }, [_vm._v("*")]) : _vm._e()]), _c('div', {
    staticClass: "input__relative"
  }, [_c('b-form-input', _vm._g(_vm._b({
    class: `bg-${_vm.classText} border input__field`,
    attrs: {
      "id": "example-input",
      "type": "date",
      "placeholder": _vm.placeholder,
      "autocomplete": "off",
      "max": "9999-12-31"
    }
  }, 'b-form-input', _vm.$attrs, false), _vm.$listeners)), _c('div', {
    staticClass: "append--icon"
  }, [_c('b-form-datepicker', {
    class: `bg-${_vm.classText} border-0`,
    attrs: {
      "button-only": "",
      "right": "",
      "button-variant": "transparent",
      "locale": "en-US"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('ba-base-icon', {
          attrs: {
            "height": "25px",
            "name": "ba-calendar"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }