var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex h-100"
  }, [_c('div', {
    staticClass: "bg-black ba-navbar__icon d-flex justify-content-center align-items-center"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-logo"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }