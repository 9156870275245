
import Vue from "vue";

export default Vue.extend({
    props: {
        text: { type: [String, Number], required: true },
        variant: { type: String, required: false, default: "" },
    },
    data(): { colorMappings: { [key: string]: string } } {
        return {
            colorMappings: {
                "Admin": "bg-blue-10 text-blue-90",
                "Profile-Only": "bg-red-100 text-red-70",
                "ReportCreator": "bg-gray-20 text-black-90",
                "ReportReviewer": "bg-yellow-10 text-yellow-150",
                "ReportSignatory": "bg-green-10 text-green-70",
                "Draft": "bg-blue-10 text-blue-90",
                "InReview": "bg-yellow-10 text-yellow-150",
                "Approved": "bg-green-10 text-green-70",
            },
        };
    },
    computed: {
        badgeColors(): string {
            return this.colorMappings[this.text];
        },
    },
});
