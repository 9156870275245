
import Vue from "vue";

export default Vue.extend({
    name: "BaNumberField", // vue component name
    inheritAttrs: true,
    props: {
        label: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
        },
        value: {
            type: [Number, String],
            default: 0,
        },
        tile: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formValue: this.value,
        };
    },
    watch: {
        formValue(v) {
            this.$emit("input", v);
        },
    },
});
