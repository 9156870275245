
import Vue from "vue";

export default Vue.extend({
    inheritAttrs: true,
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        loadingText: {
            type: String,
            default: "",
        },
    },
});
