var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center justify-content-center pointer"
  }, [_c('ba-base-icon', {
    staticClass: "mr-2",
    attrs: {
      "name": "ba-chevron-right"
    }
  }), _c('ba-base-icon', {
    attrs: {
      "name": "ba-menu-dots",
      "height": "30px"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }