
import Vue from "vue";

export default Vue.extend({
    props: {
        backText: {
            type: String,
            required: false,
            default: "Back",
        },
        textOne: {
            type: String,
            required: false,
            default: "",
        },
        textTwo: {
            type: String,
            required: false,
            default: "",
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    },
});
