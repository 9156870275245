var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(require(`@/assets/svgs/${_vm.name}.svg`), _vm._b({
    tag: "Component",
    staticClass: "base-icon",
    style: {
      height: `${_vm.height}`,
      width: `${_vm.width}`,
      minHeight: `${_vm.height}`,
      minWidth: `${_vm.width}`
    },
    on: {
      "v-on": _vm.$listeners
    }
  }, 'Component', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }