<template>
    <li
        class="m-0 base-dropdown__item d-flex align-items-center justify-content-between w-100"
        :class="{ 'base-dropdown__item--logout': ['Log Out', 'Delete Comment'].includes(text) }"
        :style="{ fontWeight }">
        <span class="d-flex align-items-center base-dropdown__item--span pointer-none">
            <span v-if="icon" class="base-dropdown__icon d-flex justify-content-center align-items-center">
                <ba-base-icon :name="icon" height="auto" width="auto" />
            </span>
            {{ text }}
        </span>
        <slot name="icon" />
    </li>
</template>

<script>
export default {
    name: "BaseDropdownItem",
    props: {
        icon: { type: String, required: false },
        text: { type: String, required: true },
        fontWeight: { type: String, required: false, default: "400" },
    },
};
</script>

<style lang="scss" scoped>
.base-dropdown__icon {
    min-width: 20px;
    min-height: 20px;
}
.base-dropdown__item {
    gap: 10px;
    padding: 16px 20px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 14px;
    line-height: 20px;
    color: $black-90;

    > span {
        gap: 10px;
    }

    &:hover {
        background-color: rgba(120, 118, 118, 0.05);
    }

    &--logout {
        color: #db6345;
    }
}
</style>
