var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-badge', {
    class: [_vm.badgeColors, 'ba-badge', `ba-badge--${_vm.variant}`]
  }, [_vm.text === 'ReportCreator' ? [_vm._v("Creator")] : _vm.text === 'ReportSignatory' ? [_vm._v("Signatory")] : _vm.text === 'ReportReviewer' ? [_vm._v("Reviewer")] : _vm.text === 'Draft' ? [_vm._v("Draft")] : _vm.text === 'InReview' ? [_vm._v("In Review")] : _vm.text === 5 ? [_vm._v("Submitted to the FRC")] : [_vm._v(_vm._s(_vm.text))]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }