var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    staticClass: "p-4 bg-white background",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "text-md-1 mb-4",
    attrs: {
      "sm": "4"
    }
  }, [_vm._v(" Stakeholders ")])], 1), _c('b-row', {
    staticClass: "my-1"
  }, [_c('b-col', {
    staticClass: "sub_stakeholders",
    attrs: {
      "sm": "4"
    }
  }, [_vm._v(" Internal Stakeholders ")]), _c('b-col', {
    staticClass: "search position-relative",
    attrs: {
      "sm": "8 "
    }
  }, [_c('label', {
    staticClass: "text-sm-3"
  }, [_vm._v("Primary Stakeholders")]), _c('div', {
    staticClass: "img position-absolute"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-search"
    }
  })], 1), _c('b-form-input', {
    staticClass: "search-input py-4 pr-4 pl-5 bg-neutral border border-gray-500 mb-4",
    attrs: {
      "placeholder": "Search"
    },
    model: {
      value: _vm.search1,
      callback: function ($$v) {
        _vm.search1 = $$v;
      },
      expression: "search1"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "my-1"
  }, [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('label', {
    staticClass: "mb-1 text-sm-1"
  }, [_vm._v("SELECTED")]), _c('div', {
    staticClass: "selected_executive_main position-relative border border-left-0 border-right-0 pt-2 pb-4 pl-2 border-top-gray-500 border-bottom-gray-500"
  }, [_c('span', {
    staticClass: "line position-absolute bg-warning"
  }), _c('div', {
    staticClass: "selected_executive d-flex justify-content-between bg-neutral align-items-center rounded-sm ml-2 p-2"
  }, [_c('div', {
    staticClass: "avatar d-flex align-items-center"
  }, [_c('div', [_c('ba-base-icon', {
    attrs: {
      "name": "ba-executive"
    }
  })], 1), _c('div', {
    staticClass: "name_mail ml-2"
  }, [_c('div', {
    staticClass: "text-sm-3 black-90"
  }, [_vm._v(" Christina Ibara ")]), _c('div', {
    staticClass: "text-sm-1 text-black-80"
  }, [_vm._v(" christina.ibara@bodadmin.com ")])])]), _c('div', {
    staticClass: "text-sm-2 text-blue-800 d-flex align-content-center px-2 bg-blue-100 designation"
  }, [_c('p', [_vm._v("Executive")])]), _c('div', {
    staticClass: "text-danger"
  }, [_vm._v("x")])])])])], 1), _c('b-row', {
    staticClass: "my-1"
  }, [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }), _c('b-col', {
    attrs: {
      "sm": "8 search"
    }
  }, [_c('label', {
    staticClass: "text-sm-3"
  }, [_vm._v("Line Manager")]), _c('div', {
    staticClass: "img position-absolute"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-search"
    }
  })], 1), _c('b-form-input', {
    staticClass: "search-input py-4 pr-4 pl-5 bg-neutral border border-gray-500 mb-4",
    attrs: {
      "placeholder": "Search"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "my-1"
  }, [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('label', {
    staticClass: "text-sm-1"
  }, [_vm._v("SELECTED")]), _c('div', {
    staticClass: "selected_executive_main position-relative border border-left-0 border-right-0 pt-2 pb-4 pl-2 border-top-gray-500 border-bottom-0"
  }, [_c('span', {
    staticClass: "line position-absolute bg-warning"
  }), _c('div', {
    staticClass: "selected_executive d-flex justify-content-between bg-neutral align-items-center rounded-sm ml-2 p-2"
  }, [_c('div', {
    staticClass: "avatar d-flex align-items-center"
  }, [_c('div', [_c('ba-base-icon', {
    attrs: {
      "name": "ba-employee"
    }
  })], 1), _c('div', {
    staticClass: "name_mail ml-2"
  }, [_c('div', {
    staticClass: "text-sm-3 text-black-90"
  }, [_vm._v(" Joshua Oluwagbemiga ")]), _c('div', {
    staticClass: "text-sm-1 text-black-80"
  }, [_vm._v(" joshua.oluwagbemiga@bodadmin.com ")])])]), _c('div', {
    staticClass: "text-sm-2 text-blue-800 d-flex align-content-center px-2 bg-blue-100 designation"
  }, [_c('p', [_vm._v("Employee")])]), _c('div', {
    staticClass: "text-danger"
  }, [_vm._v("x")])])])])], 1), _c('b-row', {
    staticClass: "mt-8"
  }, [_c('b-col', {
    staticClass: "sub_stakeholders",
    attrs: {
      "sm": "4"
    }
  }, [_vm._v(" External Stakeholders ")]), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('label', {
    staticClass: "text-sm-1 mb-1"
  }, [_vm._v("FULL NAME")]), _c('b-form-input', {
    staticClass: "input mb-2 pointer",
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "my-1"
  }, [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('label', {
    staticClass: "text-sm-1 mb-1"
  }, [_vm._v("EMAIL ADDRESS")]), _c('b-form-input', {
    staticClass: "input mb-2 pointer",
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('label', {
    staticClass: "text-sm-1 mb-1"
  }, [_vm._v("PHONE")]), _c('vue-phone-number-input', _vm._b({
    staticClass: "input mb-2 pointer",
    model: {
      value: _vm.vuePhone.phone,
      callback: function ($$v) {
        _vm.$set(_vm.vuePhone, "phone", $$v);
      },
      expression: "vuePhone.phone"
    }
  }, 'vue-phone-number-input', _vm.vuePhone.props, false))], 1)], 1), _c('b-row', {
    staticClass: "my-1"
  }, [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('label', {
    staticClass: "text-sm-1 mb-1"
  }, [_vm._v("AGENCY")]), _c('b-form-input', {
    staticClass: "input mb-2 pointer",
    model: {
      value: _vm.agency,
      callback: function ($$v) {
        _vm.agency = $$v;
      },
      expression: "agency"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "my-1"
  }, [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('label', {
    staticClass: "text-sm-1 mb-1"
  }, [_vm._v("DEPARTMENT")]), _c('b-form-input', {
    staticClass: "input mb-2 pointer",
    model: {
      value: _vm.department,
      callback: function ($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "my-1"
  }, [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('label', {
    staticClass: "text-sm-1 mb-1"
  }, [_vm._v("ADDITIONAL INFORMATION")]), _c('b-textarea', {
    staticClass: "textarea mb-2 pointer",
    model: {
      value: _vm.information,
      callback: function ($$v) {
        _vm.information = $$v;
      },
      expression: "information"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "my-1"
  }, [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('div', {
    staticClass: "mt-3 mb-6 rounded-sm d-flex align-items-center justify-content-center pointer text-center text-md-1 text-blue-800 add_stakeholder"
  }, [_vm._v(" Add External Stakeholder ")])])], 1), _c('b-row', {
    staticClass: "buttons my-1"
  }, [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }), _c('b-col', {
    attrs: {
      "sm": "5"
    }
  }, [_c('b-button', {
    staticClass: "pointer text-md-1 mb-2 py-2 rounded-sm px-4",
    attrs: {
      "variant": "outline-info"
    }
  }, [_vm._v("Save and continue later")])], 1), _c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "sm": "3 back_next"
    }
  }, [_c('b-button', {
    staticClass: "pointer text-black text-md-1 py-1 px-4 rounded-sm",
    attrs: {
      "variant": "light"
    }
  }, [_vm._v("Back")]), _c('b-button', {
    staticClass: "pointer text-black text-md-1 py-1 px-4 rounded-sm ml-4",
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Next")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }